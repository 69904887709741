import { useState, useEffect } from "react"
import { CSSTransition } from "react-transition-group"


/**
 * Modal component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Flag indicating whether the modal is open or not.
 * @param {string} props.title - The title of the modal.
 * @param {string} props.description - The description of the modal.
 * @param {ReactNode} props.children - The content of the modal.
 * @param {function} props.onClose - The function to be called when the modal is closed.
 * @param {string} [props.size="md"] - The size of the modal. Defaults to "md".
 * @param {function} props.handle - The function to be called when the modal is handled.
 * @param {Object} [props.extraClasses={}] - Additional CSS classes for customization.
 * @param {boolean} [props.disableClose=false] - Flag indicating whether the modal can be closed or not.
 * @returns {JSX.Element} The Modal component.
 */
const Modal = ({ open, title, description, children, onClose, size = "md", handle, extraClasses = {}, disableClose = false}) => {
    const [isOpen, setOpen] = useState(false)

    const onClick = () => {
        !disableClose && setOpen(false)
        handle && handle(false)
        onClose && onClose()
    }

    useEffect(() => setOpen(open), [open])

    return (
        <CSSTransition in={isOpen} timeout={250}>
            <div className="modal">
                <div className={`modal__content modal__content--${size} ${extraClasses?.content || ""}`}>
                    {!disableClose && (
                        <button type="button" className="modal__close" onClick={onClick}>
                            <span className="sr-only">Fermer la modale</span>
                            <i className="vnf-icons-icon-m-croix-fermer-outline"></i>
                        </button>
                    )}
                    <div className="modal__header">
                        {title && <h2 className="modal__title">{title}</h2>}
                        {description && <p className={`modal__description ${extraClasses?.description || ""}`} >{description}</p>}
                    </div>
                    <div className={`modal__body  ${extraClasses?.body || ""}`}>
                        {children}
                    </div>
                </div>
                <div className="modal__overlay" onClick={onClick}></div>
            </div>
        </CSSTransition>
    )
}

export default Modal