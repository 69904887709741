import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/min/locales'
import hoistStatics from 'hoist-non-react-statics';
import { withToast } from '../../App';
import LoadingOverlay from 'react-loading-overlay';
import { DefaultLayout } from '../../components/Layouts';
import { formattedDuration } from '../../helpers/helpers';
import { FilterDropDown, AutoCompleteFilter, DateFilter, PerPageFilter, CheckboxFilter, SelectFilter, SortbyFilter } from '../../components/Filters';
import Pagination from '../../components/Pagination';
import { CONNECTOR_TYPES } from 'config';
import { API_URL } from '../../config';
import TransactionModal from './_transactionModal'
import IconButton from 'components/IconButton';
import IconLink from '../../components/IconLink';
import ScrollContainer from 'react-indiana-drag-scroll';

moment.locale('fr')

class Transactions extends CustomComponent {
    constructor(props) {
        super(props);

        const urlParams = new URLSearchParams(window.location.search)

        this.state = {
            transaction: {
                list: [],
            },
            openTransaction: null,
            role: [],
            filter: {
                unplugDateStart: null,
                unplugDateEnd: null,
                connectorType: 'ALL',
                terminal: urlParams.get('terminal') || '',
                terminal_suggestions: [],
                boat: this.props.match.params.ship_name,
                boat_suggestions: [],
                boat_owner: '',
                boat_owner_suggestions: [],
                per_page: 15,
                ignore_null_transactions: true,
            },
            sortField: 'connection',
            sortDirection: 'DESC',
            isActive: true,
            currentPage: 1,
            pageTotal: 1,
        }
        this._isMounted = false;
        this.timeout = null
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.checkIsConnectedPWA()) {
            //Load localstorage value
            this.setState({ role: this.loadRoles() });
            this._isMounted && this.getTransactions(this.props.match.params.cpo_id, this.props.match.params.ship_id);
        }
    }

    componentWillUnmount() {
        if (this.timeout) clearTimeout(this.timeout)
    }

    paginationOnClick = (e) => {
        const value = e.currentTarget.value;
        this.setState(({
            currentPage: value,
        }), () => this.getTransactions(this.props.match.params.cpo_id))
    }

    perPageOnClick = (e) => {
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                per_page: e.target.value
            },
            currentPage: 1,
        }), () => this.getTransactions(this.props.match.params.cpo_id))
    }

    async getTransactions (cpo_id = undefined, boat_id = undefined) {
        const page = this.state.currentPage;
        const limit = this.state.filter.per_page;
        const ignore_null_transactions = this.state.filter.ignore_null_transactions;
        const unplug_date_start = moment(this.state.filter.unplugDateStart);
        const unplug_date_end = moment(this.state.filter.unplugDateEnd);
        const terminal = this.state.filter.terminal;
        const boat = this.state.filter.boat;
        const connector_type = this.state.filter.connectorType;
        const boat_owner = this.state.filter.boat_owner;
        const sortField = this.state.sortField;
        const sortDirection = this.state.sortDirection;

        let url = `/transactions/?limit=${limit}&page=${page}`;
        if (ignore_null_transactions) url += `&filter_empty=true`;
        if (unplug_date_start._isValid) url += `&unplug_date_start=${unplug_date_start.format(moment.HTML5_FMT.DATETIME_LOCAL_MS)}`;
        if (unplug_date_end._isValid) url += `&unplug_date_end=${unplug_date_end.format(moment.HTML5_FMT.DATETIME_LOCAL_MS)}`;
        if (terminal !== '') {
            url += `&terminal=${terminal}`;
        } else if (cpo_id !== undefined) {
            url += `&terminal=${cpo_id}`;
        }
        if (boat !== '') {
            url += `&boat=${boat}`;
        } else if (boat_id) {
            url += `&boat_id=${boat_id}`;
        }
        if (connector_type !== 'ALL') url+= `&connector_type=${connector_type}`;
        if (boat_owner !== '') url+= `&boat_owner=${boat_owner}`;
        if (sortField) url+= `&order_on=${(sortField === "connection")? "unplug_date_start": "unplug_date_end"}`;
        if (sortDirection) url+= `&order_dir=${sortDirection}`;

        const method = "GET";

        let data = await this.request(url, method);

        if (data?.transactions) {
            this._isMounted && this.setState(prevState => ({
                transaction: {
                    ...prevState.transaction,
                    list: data.transactions,
                },
                isActive: false,
                pageTotal: Math.ceil(data.nbPages) || 1,
            }), () => {
                localStorage.setItem('transaction', JSON.stringify(this.state.transaction));
            });
        }
    }

    handleDateFilter = (value, name) => {
        const can_update = !value || moment(value)._isValid

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [name]: value
            }
        }),() => can_update && this.getTransactions(this.props.match.params.cpo_id))
    }

    updateSuggestion = async (e) => {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(async () => {
            const {value, name} = e.target

            const url = `/transactions/autocomplete/${name}?q=${value}`;
            const method = "GET";
            let data = await this.request(url, method);

            if (data?.strings) {
                this.setState(prevState => ({
                    filter: {
                        ...prevState.filter,
                        [`${name}_suggestions`]: data.strings,
                        [name]: value
                    }
                }))
            }
        }, 500)
    }

    handleAutocomplete = async (e) => {
        const {value, name} = e.target

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                [`${name}_suggestions`]: [],
                [name]: value
            }
        }), () => this.getTransactions(this.props.match.params.cpo_id))
    }

    handleSort = (field) => {
        this.setState({
            sortField: field,
            sortDirection: (field !== this.state.sortField || this.state.sortDirection === 'DESC') ? 'ASC' : 'DESC'
        }, () => this.getTransactions())
    }

    getXlsExportUrl = () => API_URL + "/transactions/exportall" + this.getUrlParams()

    getUrlParams = () => {
        const {
            boat,
            boat_owner,
            unplugDateStart,
            unplugDateEnd,
            terminal,
            ignore_null_transactions,
            sortField,
            sortDirection
        } = this.state.filter

        let filters = []
        if (boat) filters.push(`boat=${boat}`)
        if (boat_owner) filters.push(`boat_owner=${boat_owner}`)
        if (terminal) filters.push(`terminal`)
        if (ignore_null_transactions) filters.push('filter_empty=true')
        if (sortField) filters.push(`order_on=${(sortField === "connection")? "unplug_date_start": "unplug_date_end"}`)
        if (sortDirection) filters.push(`order_dir=${sortDirection}`)
        const unplug_date_start = moment(unplugDateStart)
        if (unplug_date_start._isValid) filters.push(`unplug_date_start=${unplug_date_start.format(moment.HTML5_FMT.DATETIME_LOCAL_MS)}`)
        const unplug_date_end = moment(unplugDateEnd)
        if (unplug_date_end._isValid) filters.push(`unplug_date_end=${unplug_date_end.format(moment.HTML5_FMT.DATETIME_LOCAL_MS)}`)

        return filters.length > 0
            ? `?${filters.join('&')}`
            : ``
    }

    render () {
        const {t} = this.props

        return (
            <DefaultLayout>
                <h1 className="main-title">{t('transaction_history')}</h1>
                <LoadingOverlay active={this.state.isActive} classNamePrefix="loader_" spinner text={t('loading')}>
                    <div className="table-header__container table-header__container--with-action">
                        <div className="table-header table-header--blue">
                            <h2 className="table-header__title visible-desktop">{t('transaction_history')}</h2>
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="row">
                                        <div className="col-lg-6 mb-4">
                                            <DateFilter
                                                id="unplugDateStart"
                                                name="unplugDateStart"
                                                label={t('connection_start_date')}
                                                value={this.state.filter.unplugDateStart}
                                                placeholder={t('unplugDateStart_date')}
                                                showLabel
                                                handle={(value) => this.handleDateFilter(value, 'unplugDateStart')}/>
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <DateFilter
                                                minDate={this.state.filter.unplugDateStart}
                                                minDateMessage={t('min_date_message_transactions')}
                                                id="unplugDateEnd"
                                                name="unplugDateEnd"
                                                value={this.state.filter.unplugDateEnd}
                                                label={t('connection_end_date')}
                                                showLabel
                                                handle={(value) => this.handleDateFilter(value, 'unplugDateEnd')}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FilterDropDown>
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div className="row">
                                            <div className="col-lg-6 mb-4">
                                                <AutoCompleteFilter
                                                    id="terminal"
                                                    name="terminal"
                                                    value={this.state.filter.terminal}
                                                    label={t('terminal')}
                                                    showLabel
                                                    suggestions={this.state.filter.terminal_suggestions}
                                                    onFieldChange={this.updateSuggestion}
                                                    handle={this.handleAutocomplete} />
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <AutoCompleteFilter
                                                    id="boat"
                                                    name="boat"
                                                    value={this.state.filter.boat}
                                                    label={t('boat')}
                                                    showLabel
                                                    suggestions={this.state.filter.boat_suggestions}
                                                    onFieldChange={this.updateSuggestion}
                                                    handle={this.handleAutocomplete} />
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <SelectFilter
                                                    id="connectorType"
                                                    name="connectorType"
                                                    value={this.state.filter.connectorType}
                                                    label={t('connector_type')}
                                                    showLabel
                                                    options={CONNECTOR_TYPES.map(option => ({value: option, name: option}))}
                                                    handle={(e) => this.handleInputChange(e, null, 'filter', () => this.getTransactions(this.props.match.params.cpo_id))} />
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <AutoCompleteFilter
                                                    id="boat_owner"
                                                    name="boat_owner"
                                                    value={this.state.filter.boat_owner}
                                                    label={t('boat_owner')}
                                                    showLabel
                                                    suggestions={this.state.filter.boat_owner_suggestions}
                                                    onFieldChange={this.updateSuggestion}
                                                    handle={this.handleAutocomplete} />
                                            </div>
                                            <div className="col-12">
                                                <CheckboxFilter
                                                    id="ignore_null_transactions"
                                                    name="ignore_null_transactions"
                                                    value={this.state.filter.ignore_null_transactions}
                                                    label={t('ignore_null_transactions')}
                                                    handle={(e) => this.handleInputChange(e, null, 'filter', () => this.getTransactions(this.props.match.params.cpo_id))} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FilterDropDown>

                        </div>
                        <div className="table-header__actions">
                            <a href={this.getXlsExportUrl()} className="table-header__link"><i className="vnf-icons-icon-m-telechargement-outline"></i>{t('export_to_xls')}</a>
                            <PerPageFilter
                                name="per_page"
                                options={[15,25,50]}
                                value={this.state.filter.per_page}
                                handle={(e) => this.perPageOnClick(e)}/>
                        </div>
                    </div>
                    <ScrollContainer className="card-table__wrapper" hideScrollbars={false}>
                        <table className="card-table card-table--blue">
                            <thead>
                                <tr>
                                    <th>{t('transaction_id')}</th>
                                    {/* <th>{t('place')}</th> */}
                                    <th>{t('terminal')}</th>
                                    <th>{t('connector_count')}</th>
                                    <th>{t('connector_type')}</th>
                                    <th>{t('consumption')}</th>
                                    <th>{t('duration')}</th>
                                    <th><SortbyFilter label={t('connection')} active={this.state.sortField === 'connection'} direction={this.state.sortDirection} onClick={() => this.handleSort('connection')}/></th>
                                    <th><SortbyFilter label={t('disconnection')} active={this.state.sortField === 'disconnection'} direction={this.state.sortDirection} onClick={() => this.handleSort('disconnection')}/></th>
                                    <th>{t('boat_owner')}</th>
                                    <th>{t('boat')}</th>
                                    <th>{t('amount_with_tax')}</th>
                                    {this.state.role.includes("ROLE_SUPER_ADMIN") && <th>{t('Actions')}</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.transaction.list.map((transaction) => (
                                    <tr key={transaction.id}>
                                        <td className="card-table__min-width" data-label={t('transaction_id')}>{transaction.transactionId}</td>
                                        {/* <td data-label={t('place')}>{transaction.connector.terminal.address}</td> */}
                                        <td data-label={t('terminal')}>{transaction.connector.terminal.cpoTerminalId}</td>
                                        <td data-label={t('connector_count')}>{transaction.connector.cpoConnectorId}</td>
                                        <td data-label={t('connector_type')}>{t(transaction.connector.capacity)}</td>
                                        <td data-label={t('consumption')}>
                                            {!!transaction.kwhConsumed && transaction.kwhConsumed + 'kWh'}
                                            {!!transaction.m3Consumed && transaction.m3Consumed + 'm3'}
                                        </td>
                                        <td data-label={t('duration')}>{
                                            (transaction.startTime && transaction.endTime)
                                                ? formattedDuration(transaction.startTime.date, transaction.endTime.date)
                                                : <span className="card-table__pending"><i className="vnf-icons-icon-m-montre-outline"></i>{t('in_progress')}</span> 
                                        }</td>
                                        <td data-label={t('connection')}>{transaction.startTime ? moment(transaction.startTime.date).format(t('DATE_HOURS_FORMAT')) : '-'}</td>
                                        <td data-label={t('disconnection')}>{transaction.endTime ? moment(transaction.endTime.date).format(t('DATE_HOURS_FORMAT')) : '-'}</td>
                                        <td data-label={t('boat_owner')}>{transaction.boat.armateur.company.name}</td>
                                        <td data-label={t('boat')}>{transaction.boat.name}</td>
                                        <td data-label={t('amount_with_tax')}>{transaction.amount} €</td>
                                        <td className="card-table__actions card-table__min-width">
                                            <div className="card-table__actions-wrapper">
                                                <IconButton
                                                    onClick={() => this.setState({openTransaction: transaction})}
                                                    icon="vnf-icons-icon-m-detail-outline"
                                                    focusIcon="vnf-icons-icon-m-detail-outline"
                                                    color="bleu-a3"
                                                    label={t('Show')}/>
                                                <IconLink
                                                    external
                                                    href={ API_URL + "/transactions/export/" + transaction.id}
                                                    icon="vnf-icons-icon-m-telechargement-outline"
                                                    focusIcon="vnf-icons-icon-m-telechargement-outline"
                                                    color="bleu-a3"
                                                    label={t('download')}/>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </ScrollContainer>

                    {(this.state.pageTotal > 1 && this.state.transaction.list.length > 0) && (
                        <Pagination handleClick={this.paginationOnClick} pageCurrent={this.state.currentPage} pageTotal={this.state.pageTotal} pageDisplay={3} />
                    )}

                    {this.state.openTransaction && (
                        <TransactionModal transaction={this.state.openTransaction} onClose={() =>this.setState({openTransaction: null})}/>
                    )}
                </LoadingOverlay>
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(Transactions)), Transactions));