import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import LoadingOverlay from 'react-loading-overlay';
import { withToast } from '../../App';
import { DefaultLayout } from 'components/Layouts';
import Pagination from '../../components/Pagination';
import { TextFilter, PerPageFilter, CheckboxFilter } from '../../components/Filters';
import IconButton from '../../components/IconButton';
import RefreshModal from './_refreshModal'
import DeleteModal from './_deleteModal'
import EditModal from './_editModal'
import CreateModal from './_createModal'
import { matchRoles } from '../../helpers/helpers';
import ScrollContainer from 'react-indiana-drag-scroll';
import IconLink from '../../components/IconLink';

class Ship extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            ship: {
                list: []
            },
            roles: [],
            isActive: true,
            currentPage: 1,
            pageTotal: 1,
            filter: {
                search: '',
                per_page: 15,
                show_deleted: false
            },
            refreshShip: null, // ship or null
            editShip: null, // ship or null
            deleteShip: null, // ship or null
            createShip: false, // true (open) or false
            sortField: null,
            sortDirection: 'ASC',
        }
        this._isMounted = false;
        this.timeout = null
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.checkIsConnectedPWA()) {
            //Load localstorage value
            this.loadStorageValues(['ship'], [this.state.ship]);
            this.setState({ roles: this.loadRoles() });
            this._isMounted && this.getShips(this.props.match.params.id);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.timeout) clearTimeout(this.timeout)
    }

    paginationOnClick = (e) => {
        const value = e.currentTarget.value
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                search: ''
            },
            currentPage: value,
        }), () => {this.getShips(this.props.match.params.id)})
    }

    perPageOnClick = (e) => {
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                per_page: e.target.value
            },
            currentPage: 1,
        }), () => this.getShips(this.props.match.params.id))
    }

    onFilterInputChange = (e) => {
        const term = e.target.value

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                search: term
            },
        }), () => {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => this.getShips(this.props.match.params.id), 500)
        })
    }

    onShowDeletedChange = (e) => {
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                show_deleted: e.target.checked
            },
        }), () => this.getShips(this.props.match.params.id))
    }

    async getFile(id, file_type) {
        const {t} = this.props

        let url = "/boat/" + id + "/files";
        let method = "GET";
        let files = await this.request(url, method);

        if (files) {
            try {
                window.open(files[file_type], '_blank').focus();
            } catch (_e) {
                this.props.addToast(t('blocked_file_popup'), {appearance: 'warning'})
            }
        }
    }

    async getShips(id = undefined) {
        const page = this.state.currentPage;
        const limit = this.state.filter.per_page;
        let url = `/boat/?limit=${limit}&page=${page}`;
        if (id) url = `/boat/?user_id=${id}&limit=${limit}&page=${page}`;
        if (this.state.filter.search !== '') url += `&q=${this.state.filter.search}`
        if (this.state.filter.show_deleted) url += "&deleted=1"
        const method = "GET";

        this.setState({
            currentPage: page
        })

        let data = await this.request(url, method);
        if (data?.boats) {
            this._isMounted && this.setState(prevState => ({
                ship: {
                    ...prevState.ship,
                    list: data.boats
                },
                isActive: false,
                pageTotal: Math.ceil(data.nbPages) || 1,
            }), () => {
                localStorage.setItem('ship', JSON.stringify(this.state.ship));
            });
        }
        else {
            this.setState({ isActive: false });
        }
    }

    isUserAdminOrOwner = (armateurId) => {
        return (
            !this.state.filter.show_deleted &&
            (
                matchRoles(this.state.roles, ["ROLE_ADMIN"]) ||
                (matchRoles(this.state.roles, ["ROLE_ARMATEUR"]) && armateurId === this.getLocalStorageAccount().id)
            )
        );
    }

    handleSort = (field) => {
        this.setState({
            sortField: field,
            sortDirection: (field !== this.state.sortField || this.state.sortDirection === 'DESC') ? 'ASC' : 'DESC'
        }, () => this.getShips())
    }

    render() {
        const { t } = this.props;
        return (
            <DefaultLayout>
                <LoadingOverlay active={this.state.isActive} classNamePrefix="loader_" spinner text={t('loading')}></LoadingOverlay>
                <h1 className="main-title">{t('ships')}</h1>
                    <div className="table-header__container table-header__container--with-action">
                        <div className="table-header table-header--blue">
                            <h2 className="table-header__title">{t('ships_list')}</h2>
                            <div className="row">
                                <div className="col-md-6">
                                    <TextFilter id="filter-users" name="filter-users" value={this.state.filter.search} label={t('filter_user_placeholder')} placeholder={t('filter_user_placeholder')} handle={this.onFilterInputChange} />
                                </div>
                                <div className="col-12 mt-4">
                                    <CheckboxFilter id="show_deleted" name="show_deleted" value={this.state.filter.show_deleted} label={t('show_deleted_boats')} handle={this.onShowDeletedChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="table-header__actions">
                            {matchRoles(this.state.roles, ["ROLE_SUPER_ADMIN", "ROLE_ARMATEUR"]) && (
                                <button type="button" className="table-header__link" onClick={() => this.setState({createShip: true})}><i className="vnf-icons-icon-m-plus-rond-solid"></i>{t('add_ship')}</button>
                            )}
                            <PerPageFilter
                                name="per_page"
                                options={[15,25,50]}
                                value={this.state.filter.per_page}
                                handle={(e) => this.perPageOnClick(e)}/>
                        </div>
                    </div>

                    <ScrollContainer className="card-table__wrapper" hideScrollbars={false}>
                        <table className="card-table card-table--blue">
                            <thead>
                                <tr>
                                    <th>{t('status')}</th>
                                    <th>{t('eni')}</th>
                                    <th>{t('ship_name')}</th>
                                    <th>{t('Email')}</th>
                                    <th>{t('ship_phone')}</th>
                                    <th>{t('boat_owner')}</th>
                                    <th>{t('community_certificate')}</th>
                                    <th>{t('vat_exemption_file')}</th>
                                    <th>{t('numbers_of_transactions')}</th>
                                    {matchRoles(this.state.roles, ["ROLE_SUPER_ADMIN", "ROLE_ARMATEUR"]) && (
                                        <th>{t('Actions')}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.ship.list.map((value) =>
                                        <tr key={value.id}>
                                            <td className={`card-table__status card-table__status--top card-table__status--${value.deleted ? 'indisponible' : 'disponible'}`}>
                                                <span className="sr-only">{value.deleted ? t('deleted') : t('active')}</span>
                                            </td>
                                            <td data-label={t('eni')}>{value.eni}</td>
                                            <td data-label={t('ship_name')}>{value.name}</td>
                                            <td data-label={t('ship_email')}>{value.email}</td>
                                            <td data-label={t('ship_phone')}>{value.phone}</td>
                                            <td data-label={t('boat_owner')}>
                                                {value.armateur.company ?
                                                    value.armateur.company.name
                                                    : <i className="vnf-icons-icon-m-croix-fermer-solid"></i>
                                                }
                                            </td>
                                            <td data-label={t('community_certificate')}>
                                                {value.certificate_file
                                                    ? <IconButton label={t('see_file')} icon="vnf-icons-icon-m-document-outline" focusIcon="vnf-icons-icon-m-document-solid" color="bleu-a4" onClick={() => {this.getFile(value.id, "certificate")}}/>
                                                    : <i className="vnf-icons-icon-m-croix-fermer-solid"></i>
                                                }
                                            </td>
                                            <td data-label={t('vat_exemption_file')}>
                                                {value.vat_exemption_file
                                                    ? <IconButton label={t('see_file')} icon="vnf-icons-icon-m-document-outline" focusIcon="vnf-icons-icon-m-document-solid" color="bleu-a4" onClick={() => {this.getFile(value.id, "vat_exemption")}}/>
                                                    : <i className="vnf-icons-icon-m-croix-fermer-solid"></i>
                                                }
                                            </td>
                                            <td data-label={t('numbers_of_transactions')}>{value.transactions_count}</td>
                                            <td className="card-table__actions card-table__min-width">
                                                <div className="card-table__actions-wrapper">
                                                    <IconLink href={`/transaction/boat/${value.id}/${value.name} (${value.eni})`} label={t('show_boat_transactions')} color="bleu-a3" icon="vnf-icons-icon-m-detail-outline" focusIcon="vnf-icons-icon-m-detail-outline"/>
                                                    <IconButton disabled={!this.isUserAdminOrOwner(value.armateur.id)} label={t("RegenerateCode")} color="bleu-a3" icon="vnf-icons-icon-m-bateau-face-a-solid" focusIcon="vnf-icons-icon-m-bateau-face-a-solid" onClick={() => this.setState({refreshShip: value})}/>
                                                    {!this.state.filter.show_deleted && matchRoles(this.state.roles, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_ARMATEUR"]) && (
                                                        <IconButton label={t("Edit")} color="bleu-a3" icon="vnf-icons-icon-m-crayon-solid" focusIcon="vnf-icons-icon-m-crayon-solid" onClick={() => this.setState({editShip: value})}/>
                                                    )}
                                                    <IconButton disabled={!this.isUserAdminOrOwner(value.armateur.id)} label={t("Delete")} color="bleu-a3" icon="vnf-icons-icon-m-corbeille-solid" focusIcon="vnf-icons-icon-m-corbeille-solid" onClick={() => this.setState({deleteShip: value})}/>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </ScrollContainer>

                {(this.state.pageTotal > 1 && this.state.ship.list.length > 0) && (
                    <Pagination handleClick={this.paginationOnClick} pageCurrent={this.state.currentPage} pageTotal={this.state.pageTotal} pageDisplay={3} />
                )}

                {/* MODALS */}
                {this.state.refreshShip && (
                    <RefreshModal ship={this.state.refreshShip} onClose={() => this.setState({refreshShip: null}, this.getShips)}/>
                )}
                {this.state.deleteShip && (
                    <DeleteModal ship={this.state.deleteShip} onClose={() => this.setState({deleteShip: null}, this.getShips)}/>
                )}
                {this.state.editShip && (
                    <EditModal ship={this.state.editShip} onClose={() => this.setState({editShip: null}, this.getShips)}/>
                )}
                {this.state.createShip && (
                    <CreateModal onClose={() => this.setState({createShip: false}, this.getShips)}/>
                )}

            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(Ship)), Ship));
